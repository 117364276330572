export const COMMERCETOOLS_ACCESS_TOKEN_URL = "https://auth.europe-west1.gcp.commercetools.com";
export const COMMERCETOOLS_API_URL = "https://api.europe-west1.gcp.commercetools.com";
export const STORE_FRONT_EMAIL_SUBSCRIPTION_ENDPOINT = "Consent/newslettersignup";
export const STORE_FRONT_SESSION_ENDPOINT = "payment/session";
export const STORE_FRONT_CREATE_ORDER_ENDPOINT = "payment/order";
export const STORE_FRONT_CREATE_ORDER_WITH_TOTAL_ZERO_ENDPOINT = "payment/zeroAmountOrder";
export const STORE_FRONT_PAYMENTS_DETAILS_ENDPOINT = "payment/details";
export const STORE_FRONT_PAYMENTS_ENDPOINT = "payment";
export const STORE_FRONT_PAYMENT_METHODS_ENDPOINT = "payment/methods";
export const STORE_FRONT_ORDER_HISTORY_ENDPOINT = "Order/OrderHistory";
