import { COMMERCETOOLS_API_URL } from "@lib/constants/urlConstants";
import { useStoreApi } from "@store/index";
import { createClient, mapExchange } from "@urql/core";
import { authExchange } from "@urql/exchange-auth";
import { getSession, signOut } from "next-auth/react";
import { useMemo } from "react";
import { dedupExchange, fetchExchange, makeOperation } from "urql";

const getAuth = async () => {
    let session;
    if (typeof window !== "undefined") {
        session = await getSession();
    } else {
        session = {};
    }

    const token = session?.accessToken;
    return token ? { token } : null;
};

const addAuthToOperation = ({ authState, operation }) => {
    // the token isn't in the auth state, return the operation without changes
    if (!authState || !authState.token) {
        return operation;
    }

    // fetchOptions can be a function (See Client API) but you can simplify this based on usage
    const fetchOptions =
        typeof operation.context.fetchOptions === "function"
            ? operation.context.fetchOptions()
            : operation.context.fetchOptions || {};

    const headers = {
        ...fetchOptions.headers,
    };

    if (authState?.token) {
        headers["Authorization"] = `Bearer ${authState.token}`;
    }

    return makeOperation(operation.kind, operation, {
        ...operation.context,
        fetchOptions: {
            ...fetchOptions,
            headers,
        },
    });
};

// By always returning true, we will force every call
// to call getAuth and update the session token in the header.
const willAuthError = () => true;

/**
 * Get GraphQL Client in browser environments (frontend).
 *
 * If the user has an active session, it will add an accessToken to all requests
 */
const useClient = (options?: RequestInit) => {
    const api = useStoreApi();

    return useMemo(() => {
        return createClient({
            url: `${COMMERCETOOLS_API_URL}/${process.env.NEXT_PUBLIC_COMMERCETOOLS_PROJECT_KEY}/graphql`,
            exchanges: [
                dedupExchange,
                mapExchange({
                    async onError(error) {
                        const isInvalidTokenError = error.graphQLErrors.some(
                            (e) => e.message === "invalid_token"
                        );

                        if (isInvalidTokenError) {
                            api.getState().clearMe();
                            await signOut({ redirect: false });
                        }
                    },
                }),
                authExchange({
                    getAuth,
                    addAuthToOperation,
                    willAuthError,
                }),
                fetchExchange,
            ],
        });
    }, [options]);
};

export default useClient;
